import styled from 'styled-components';
import { colors } from '../GlobalStyle';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100vw;

  background-color: ${colors.gray100};

  padding-bottom: 100px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1440px;

  margin: 0 30px 0 30px;

  overflow-y: hidden !important;

  @media (max-width: 499px) {
    margin: 0 16px 0 16px;
  }

  iframe {
    width: 100%;
    height: 280px;

    overflow-y: hidden;
    overflow-y: hidden !important;

    html {
      overflow-y: hidden !important;
    }

    border: none;

    @media (max-width: 1440px) {
      margin-left: -48px;
    }
  }
`;

export const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 1380px;

  h1 {
    margin: 0;
    margin-top: 40px;
    font-size: 30px;

    max-width: 420px;
    max-width: 632px;

    line-height: 31px;
  }

  li::before {
    content: '•';
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  li {
    margin-left: 1em;
    margin-top: 16px;
    max-width: 632px;
  }

  h2 {
    margin-top: 32px;
    max-width: 632px;
    line-height: 31px;
  }

  h2:first-of-type {
    margin-top: 0px;
  }

  p {
    margin-top: 16px;
    max-width: 632px;
  }

  a {
    margin-top: 32px;
    height: 40px;
    display: flex;
    background: #0a2d8d;
    align-items: center;
    color: white;

    width: 100%;
    max-width: 200px;
    justify-content: center;
    gap: 12px;
    font-weight: bold;
    font-family: 'Poppins';

    border-radius: 8.4px;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }
`;

const SectionWrapper = styled.section`
  display: flex;

  justify-content: flex-start;

  width: 100%;
  max-width: 1380px;
`;

export const PrevisaoBreadCrumbsContainer = styled(SectionWrapper)`
  margin: 40px 0px;

  @media (max-width: 650px) {
    display: none;
  }
`;
