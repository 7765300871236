import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import BreadCrumbs from '@/components/BreadCrumbs';

import {
  PageWrapper,
  PageContainer,
  ContentWrapper,
  PrevisaoBreadCrumbsContainer,
} from '@/styles/pages/previsao-de-entrega';

import RedirectNewPage from '@/images/icons/icon-redirect-new-page-white.svg';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'Previsão de entrega' },
];

const PrevisaoEntregaPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "previsao-de-entrega" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Previsão de entrega"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <PrevisaoBreadCrumbsContainer>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </PrevisaoBreadCrumbsContainer>

            <ContentWrapper>
              <h1>Calcule a previsão de entrega</h1>
            </ContentWrapper>

            <iframe src="https://ssw.inf.br/2/areas?sc=N&sigla_emp=GEN&urlori=https%3A%2F%2Fwww.transportegeneroso.com"></iframe>

            <ContentWrapper>
              <h2>Documentos e informações necessárias</h2>
              <ul>
                <li>Cidade/Estado de origem (consulte a área de atuação);</li>
                <li>Cidade/Estado de destino (consulte a área de atuação);</li>
                <li>Data do embarque</li>
              </ul>
              <h2>Informações adicionais</h2>
              <ul>
                <li>
                  A data de entrega informada não contempla situações adversas
                  (como bloqueio de rodovias e questões climáticas que
                  prejudiquem o transporte) e feriados.
                </li>
                <li>
                  Este é um serviço de caráter exclusivamente informativo. Não
                  possui efeito legal.
                </li>
                <li>
                  As entregas são realizadas em dias úteis das 08h as 18h. Para
                  necessidades especiais, consulte uma das nossas Centrais de
                  Relacionamento.
                </li>
                <li>
                  Sendo pessoa física, o prazo de entrega pode ter um acréscimo
                  de cinco dias.
                </li>
              </ul>
              <h2>
                Serão acrescidos à previsão informada acima até 5 dias quando a
                origem for os seguintes estados:
              </h2>
              <p>PR, SC, RS, MG, GO, DF, MT, MS, AC, PA e RO.</p>

              <a
                href="https://cliente.generoso.com.br/cotacao/cotar"
                rel="noreferrer"
                target="_blank"
              >
                Agendar Coleta
                <img
                  src={RedirectNewPage}
                  alt="Ícone de redirecionamento para nova página"
                />
              </a>
            </ContentWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default PrevisaoEntregaPage;
